import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import authApi from '../api/auth'
import {JWT_TOKEN} from '../config'
import storage from '../libs/storage'
import AppAlert from '../components/AppAlert'
import useAuth from '../controllers/auth/useAuth'
import AppTextField from '../components/AppTextField';

import Logo from '../images/logo.png'

function Login(props) {

    const {login} = useAuth()
    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const [altro, setAltro] = useState({errore:null, loading:false, openAlert:0})

    useEffect(() => {
        const aggState = () => {
            if(state?.isCambioPwd) {
                setAltro({...altro, openAlert:1})
                navigate(pathname, {state:null});
            }
        }
        aggState()
    }, [state])

    const schema = yup.object().shape({
        email: yup.string().lowercase().required('La mail è obbligatoria.').email('La mail deve essere una email valida.'),
        password: yup.string().required('La password è obbligatoria.').min(8, 'La password deve avere almeno 8 caratteri.'),
        rememberMe: yup.boolean()
    });
    const {register, handleSubmit, formState: { errors }} = useForm({resolver: yupResolver(schema)});

    const conferma = async(valori) => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        //effettuo il login
        let result = await authApi.login({
            email: valori.email, password: valori.password
        })
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        storage.setItem(JWT_TOKEN, result.data.token)
        //scarico i dati utente
        result = await authApi.getMe()
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        //correct
        setTimeout(() => {
            setAltro({...altro, errore:null, loading:false})
            login(result.data)
            navigate('/')
        }, 300);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, openAlert:0})
    }
    
    return (
        <Box sx={{width:'100%', minHeight:'99.9vh', backgroundColor:'#f9f9f9', 
            overflow:'auto', display:'flex', justifyContent:'center', alignItems:'center'
        }}>
            <Stack direction='column' spacing='30px' sx={{alignItems:'center', paddingBottom:'80px'}}>
                <Box sx={{display:'flex', alignItems:'center', margin:'50px 0 0'}}>
                    <Box component='img' alt='' src={Logo} sx={{
                        width:'auto', height:'110px', objectFit:'contain'
                    }} />
                </Box>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Typography sx={{fontSize:'36px', fontWeight:'500', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Bentornato!</Typography>
                    <Typography sx={{paddingTop:'6px', paddingBottom:'4px', fontSize:'14px', fontWeight:'400', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#96A0AD'
                    }} dangerouslySetInnerHTML={{__html: "Effettua il login al tuo account"}}></Typography>
                </Box>

                <Box
                    component='form'
                    id="login-form"
                    name="login-form"
                    data-name="Login Form"
                    onSubmit={handleSubmit(conferma)}
                    sx={{width:{xs:'100%', sm:'fit-content'}}}
                >
                    <Stack direction='column' spacing='30px' sx={{width:{xs:'100%', sm:'fit-content'}}}>
                        <AppTextField
                            isBianco={true}
                            name={'Email'}
                            isLabel={true}
                            error={errors.email?.message}
                            placeholder={'Inserisci la tua email'}
                            register={register("email", { required: false })}
                        ></AppTextField>

                        <AppTextField
                            isBianco={true}
                            name={'Password'}
                            isLabel={true}
                            isPassword={true}
                            error={errors.password?.message}
                            placeholder={'Inserisci la tua password'}
                            register={register("password", { required: false })}
                        ></AppTextField>

                        <Typography sx={{fontSize:'14px', fontWeight:'400', cursor:'pointer',
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'primary.main'
                        }} component='a' href={'/password-dimenticata'}>Password dimenticata</Typography>
                        
                        <Box sx={{display:'flex', flexDirection:'column'}}>
                            {Boolean(altro.errore) &&
                                <Typography sx={{fontSize:'15px', marginBottom:'6px', color:'#FC2A1B', 
                                    fontWeight:'500', lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center'
                                }}>{altro.errore}</Typography>
                            }
                            <LoadingButton
                                type="submit"
                                color={"primary"}
                                variant="contained"
                                sx={{border:'none', width:'100%', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                                disableElevation={true}
                                onClick={() => null}
                                loading={altro.loading}
                                loadingPosition={'center'}
                            >
                                <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                                    letterSpacing:'-0.2', textAlign:'left', color:'white'
                                }}>Accedi</Typography>
                            </LoadingButton>
                        </Box>

                    </Stack>
                </Box>
            </Stack>

            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Password modificata con successo!'}
            ></AppAlert>
        </Box>
    );
}

export default Login;