import React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

function CellaHeader({Icon, text, width, borderLeft, borderRight, align}) {
    
    return (
        <TableCell
            sx={{
                width:width ? width : 'auto', backgroundColor:'#F9F9FF', 
                borderLeft:borderLeft ? '1px solid #dcdcdc' : 'none',
                borderRight:borderRight ? '1px solid #dcdcdc' : 'none',
                borderTop:'1px solid #dcdcdc', borderBottom:'1px solid #dcdcdc', padding:'11px'
            }}
            align={align}
        >
            <Box sx={{display:'flex', alignItems:'center'}}>
                {Boolean(Icon) && <Icon sx={{width:'18px', height:'auto', marginRight:'5px'}} />}
                <Typography sx={{fontSize:'12px', fontWeight:600}}>
                    {text}
                </Typography>
            </Box>
        </TableCell>
    );
}

export default CellaHeader;