import React from 'react';
import IconButton from '@mui/material/IconButton';

function NumeroPage({num, selected, onClick}) {

    return (
        <IconButton
            color="secondary"
            sx={{
                border:selected ? '1px solid #dcdcdc' : 'none', width:'36px', height:'36px', padding:'0',
                fontSize:'12px', fontWeight:'500', lineHeight:'normal', borderRadius:'6px', display:'flex', 
                alignItems:'center', justifyContent:'center', letterSpacing:'-0.2', color:'#6A6059'
            }}
            onClick={onClick}
        >{num}</IconButton>
    );
}

export default NumeroPage;