import { useContext } from "react";

import AuthContext from "./context";

const useAuth = () => {

    const { user, setUser } = useContext(AuthContext);

    const login = async(data) => {
        setUser({user:data, loading:false});
    }

    const logout = async() => {
        setUser({user:null, loading:false});
    }

    const aggUser = (v) => setUser({...user, user:v})
    const aggLoading = (v) => setUser({...user, loading:v})

    return { 
        user: user?.user,
        userLoading: user?.loading,

        login, 
        logout,
        aggUser,
        aggLoading
    }
}

export default useAuth;