import React, { useEffect, useState } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment/moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import AppAlert from '../components/AppAlert';
import RigaCampo from '../components/RigaCampo';
import ModalFoto from '../components/ModalFoto';
import segnalazioniApi from '../api/segnalazioni';
import AppTextField from '../components/AppTextField';
import useAgenti from '../controllers/agenti/useAgenti';
import FotoSegnalazione from '../components/FotoSegnalazione';
import useSegnalazioni from '../controllers/segnalazioni/useSegnalazioni';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';

function Segnalazione(props) {

    const {state} = useLocation()
    const navigate = useNavigate()

    const {agenti} = useAgenti()
    const {aggSegnalazione} = useSegnalazioni()

    const [agente, setAgente] = useState(null)
    const [preferred, setPreferred] = useState(false)
    const [segnalazione, setSegnalazione] = useState(null)
    const [foto, setFoto] = useState({img:null, open:false})
    const [altro, setAltro] = useState({errore:null, loading:false})

    const schema = yup.object().shape({
        cliente: yup.string('Campo non valido'),
        competitor: yup.string('Campo non valido'),
        nomeProdotto: yup.string('Campo non valido'),
        codiceProdotto: yup.string('Campo non valido'),
        prezzo: yup.number('Campo non valido'),
        commento: yup.string('Campo non valido')
    });
    const {register, handleSubmit, formState: { errors }} = useForm({resolver: yupResolver(schema)});

    useEffect(() => {
        const aggState = () => {
            if(!state?.segnalazione) return navigate('/');
            setSegnalazione(state.segnalazione)
            setPreferred(Boolean(state.segnalazione.preferred))
        }
        aggState()
    }, [state])

    useEffect(() => {
        const aggAgente = () => {
            const appo = agenti.find((el) => el.id == segnalazione.agente);
            setAgente(appo);
        }
        if(segnalazione) aggAgente()
    }, [segnalazione, agenti])

    const conferma = async(values) => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        //chiamo le api
        let result = await segnalazioniApi.modificaSegnalazione(segnalazione.id, {
            ...values, photo:segnalazione.photo, preferred:preferred
        })
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        //correct
        setTimeout(() => {
            setAltro({...altro, errore:null, loading:false})
            aggSegnalazione({...result.data})
            navigate('..', {state:{modifica:true}})
        }, 300);
    }

    const clickPreferito = async() => {
        setPreferred(!preferred)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null})
    }
    
    if(segnalazione) return (
        <Box
            component='form'
            id="cambio-pwd-form"
            name="cambio-pwd-form"
            data-name="Cambio PWD Form"
            onSubmit={handleSubmit(conferma)} 
            sx={{padding:'24px 24px 24px'}}
        >
            <Box sx={{width:'100%', padding:'0 32px', boxSizing:'border-box', borderRadius:'6px', 
                border:'1px solid #dcdcdc', display:'flex', flexDirection:'row', height:'fit-content'
            }}>
                <Box sx={{padding:'20px 0', paddingRight:'32px',
                    width:'50%', boxSizing:'border-box', borderRight:'1px solid #dcdcdc',
                }}>
                    <Typography sx={{
                        fontSize:'26px', fontWeight:'600', lineHeight:'normal', 
                        letterSpacing:'-0.2', textAlign:'left', color:'primary.main'
                    }}>Segnalazione N.{segnalazione.cont}</Typography>
                    <Stack direction='column' spacing='26px' sx={{width:'100%', paddingTop:'18px'}}>
                        <Box sx={{width:'100%'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Agente'}
                            ></RigaCampo>
                            <Box sx={{
                                width:'100%', borderRadius:'5px', border:'1px solid #DCDCDC', boxSizing:'border-box',
                                backgroundColor: 'white', fontSize:'13px', height:'40px', display:'flex',
                                alignItems:'center', cursor:'not-allowed', paddingLeft:'12px'
                            }}>
                                {agente?.fullName}
                            </Box>
                        </Box>
                        
                        <Box sx={{width:'100%'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Cliente'}
                            ></RigaCampo>
                            <AppTextField
                                width={'100%'}
                                isBianco={true}
                                isPiccolo={true}
                                name={'Cliente'}
                                isLabel={false}
                                error={errors.cliente?.message}
                                placeholder={'Inserisci il cliente'}
                                register={register("cliente", { required: false, value:segnalazione.cliente })}
                            ></AppTextField>
                        </Box>

                        <Box sx={{width:'100%'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Competitor'}
                            ></RigaCampo>
                            <AppTextField
                                width={'100%'}
                                isBianco={true}
                                isPiccolo={true}
                                name={'Competitor'}
                                isLabel={false}
                                error={errors.competitor?.message}
                                placeholder={'Inserisci il competitor'}
                                register={register("competitor", { required: false, value:segnalazione.competitor })}
                            ></AppTextField>
                        </Box>

                        <Box sx={{width:'100%'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Nome Prodotto'}
                            ></RigaCampo>
                            <AppTextField
                                width={'100%'}
                                isBianco={true}
                                isPiccolo={true}
                                name={'Nome Prodotto'}
                                isLabel={false}
                                error={errors.nomeProdotto?.message}
                                placeholder={'Inserisci il nome del prodotto'}
                                register={register("nomeProdotto", { required: false, value:segnalazione.nomeProdotto })}
                            ></AppTextField>
                        </Box>

                        <Box sx={{width:'100%'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Codice Prodotto'}
                            ></RigaCampo>
                            <AppTextField
                                width={'100%'}
                                isBianco={true}
                                isPiccolo={true}
                                name={'Codice Prodotto'}
                                isLabel={false}
                                error={errors.codiceProdotto?.message}
                                placeholder={'Inserisci il codice del prodotto'}
                                register={register("codiceProdotto", { required: false, value:segnalazione.codiceProdotto })}
                            ></AppTextField>
                        </Box>

                        <Box sx={{width:'100%'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Prezzo'}
                            ></RigaCampo>
                            <AppTextField
                                width={'100%'}
                                isBianco={true}
                                isPiccolo={true}
                                name={'Prezzo'}
                                isLabel={false}
                                error={errors.prezzo?.message}
                                placeholder={'Inserisci il prezzo del prodotto'}
                                register={register("prezzo", { required: false, value:segnalazione.prezzo })}
                            ></AppTextField>
                        </Box>
                    </Stack>
                </Box>
                
                <Box sx={{width:'50%', boxSizing:'border-box', padding:'40px 0 30px', paddingLeft:'24px'}}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'row', boxSizing:'border-box', 
                        alignItems:'flex-start', justifyContent:'space-between', padding:'0 30px 0'
                    }}>
                        <Box sx={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Data'}
                            ></RigaCampo>
                            <Typography sx={{paddingTop:'16px',
                                fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'normal', textAlign:'left', color:'black'
                            }}>{moment(segnalazione.createdAt).format('DD/MM/YYYY HH:mm')}</Typography>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Stato'}
                            ></RigaCampo>
                            <Box sx={{marginTop:'8px',
                                padding:'6px 12px', backgroundColor:segnalazione.status ? '#EFFAF3' : '#FFC2C2',
                                color:segnalazione.status ? '#8CC5AB' : 'red', fontSize:'11px', borderRadius:'10px'
                            }}>{segnalazione.status ? 'Completa' : 'Incompleta'}</Box>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <RigaCampo
                                Icon={null}
                                text={'Preferiti'}
                            ></RigaCampo>
                            <IconButton onClick={() => clickPreferito()} sx={{marginTop:'16px',
                                width:'26px', height:'26px', borderRadius:'26px', marginLeft:'12px',
                                display:'flex', alignItems:'center', justifyContent:'center'
                            }}>
                                {preferred ? <BookmarkRoundedIcon sx={{color:'primary.main'}} /> : <BookmarkBorderRoundedIcon />
                            }</IconButton>
                        </Box>
                    </Box>

                    <Box sx={{marginTop:'35px', padding:'0 30px'}}>
                        <RigaCampo
                            Icon={null}
                            text={'Foto'}
                        ></RigaCampo>
                        {!Boolean(segnalazione.photo.length) && 
                            <Typography sx={{paddingTop:'3px',
                                fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'normal', textAlign:'left', color:'black'
                            }}>Nesuna foto allegata</Typography>
                        }
                        <FotoSegnalazione
                            photo={segnalazione.photo}
                            premiFoto={(img) => setFoto({img:img, open:true})}
                        ></FotoSegnalazione>
                    </Box>

                    <Box sx={{marginTop:'50px', padding:'0 30px'}}>
                        <RigaCampo
                            Icon={null}
                            text={'Commento'}
                        ></RigaCampo>
                        <Box sx={{height:'3px'}} />
                        <AppTextField
                            width={'100%'}
                            isBianco={true}
                            isPiccolo={true}
                            name={'Commento'}
                            isLabel={false}
                            multiline={4}
                            error={errors.commento?.message}
                            placeholder={'Commento'}
                            register={register("commento", { required: false, value:segnalazione.commento })}
                        ></AppTextField>
                    </Box>
                </Box>
            </Box>

            <Stack direction='row' spacing='32px' sx={{margin:'16px 0'}}>
                <LoadingButton
                    type="submit"
                    color={"primary"}
                    variant="contained"
                    sx={{border:'none', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                    disableElevation={true}
                    onClick={() => null}
                    loading={altro.loading}
                    loadingPosition={'center'}
                >
                    <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                        letterSpacing:'-0.2', textAlign:'left', color:'white'
                    }}>Conferma</Typography>
                </LoadingButton>
                <Button
                    color={"nero"}
                    variant="contained"
                    sx={{border:'none', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                    disableElevation={true}
                    onClick={() => navigate('..')}
                >
                    <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                        letterSpacing:'-0.2', textAlign:'left', color:'white'
                    }}>Annulla</Typography>
                </Button>
            </Stack>

            <ModalFoto
                img={foto.img}
                open={foto.open}
                onClose={() => setFoto({img:null, open:false})}
            ></ModalFoto>
            <AppAlert
                open={Boolean(altro.errore?.length)}
                severity={'error'}
                chiudiAlert={chiudiAlert}
                message={altro.errore}
            ></AppAlert>
        </Box>
    );
}

export default Segnalazione;