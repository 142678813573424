import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { it } from 'date-fns/locale'
import { DateRange } from 'react-date-range'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const Backdrop = styled('div')({
    position: 'fixed', top: 0,
    right: 0, bottom: 0, left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

function ModalDate({open, onClose, date, cambiaDate, pulisciFiltro}) {

    const [dateInterne, setDateInterne] = useState(null)

    useEffect(() => {
        const aggDateInterne = () => {
            setDateInterne(date)
        }
        aggDateInterne()
    }, [date])
    
    if(dateInterne) return (<>
        <Backdrop style={{display:open ? 'block' : 'none', zIndex:3000}} onClick={onClose} />
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition keepMounted
            sx={{display:'flex', width:'100%', height:'100vh', alignItems:'center', justifyContent:'center', zIndex:'4000'}}
        >
            <Fade in={open}>
                <Box sx={{backgroundColor:'white', borderRadius:'6px', 
                    border:'1px solid #dcdcdc', overflow:'hidden', width:'775px'
                }}>
                    <Box sx={{width:'100%', height:'350px'}}>
                        <DateRange 
                            onChange={item => {
                                const appo = {...item.selection}
                                appo.isFirst = false;
                                setDateInterne([appo])
                            }}
                            maxDate={new Date()}
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={false}
                            ranges={dateInterne}
                            months={2}
                            direction='horizontal'
                            locale={it}
                            showMonthAndYearPickers={false}
                            rangeColors={['#1D00CF']}
                            showDateDisplay={false}
                            weekdayDisplayFormat="EEEEEE"
                            dayDisplayFormat="d"
                            monthDisplayFormat="LLLL Y"
                        />
                        </Box>

                    <Box sx={{width:'100%', backgroundColor:'white', display:'flex', alignItems:'center',
                        justifyContent:'space-between', padding:'12px 24px', boxSizing:'border-box', borderTop:'1px solid #dcdcdc'
                    }}>
                        <Button
                            color="rosso"
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', padding:'9px 24px', textTransform: 'none',
                                fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'center', whiteSpace:'nowrap'
                            }}
                            disableElevation={true}
                            onClick={() => {pulisciFiltro(); onClose()}}
                        >Annulla</Button>
                        <Button
                            color="verde"
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', padding:'9px 24px', textTransform: 'none',
                                fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'center', whiteSpace:'nowrap'
                            }}
                            disableElevation={true}
                            onClick={() => {cambiaDate(dateInterne); onClose()}}
                        >Applica Filto</Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
}

export default ModalDate;