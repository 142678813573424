import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

function FotoSegnalazione({photo, premiFoto}) {
    
    return (
        <Grid container spacing='24px' sx={{paddingTop:'3px'}}>
            {photo.map((img, index) => (
                <Grid item key={index} md={4}>
                    <Button sx={{
                        width:'100%', borderRadius:'6px', padding:0,
                        height:'150px', overflow:'hidden', margin:0
                    }} onClick={() => premiFoto(img)}>
                        <Box
                            component='img' alt='' src={img}
                            sx={{width:'100%', height:'150px', objectFit:'fill'}}
                        />
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}

export default FotoSegnalazione;