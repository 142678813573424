import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import utils from '../libs/utils';
import segnalazioniApi from '../api/segnalazioni';
import useAgenti from '../controllers/agenti/useAgenti';
import ZonaStatistiche from '../components/ZonaStatistiche';
import FiltriStatistiche from '../components/FiltriStatistiche';
import GraficoSegnalazioni from '../components/GraficoSegnalazioni';
import useFiltriStatistiche from '../controllers/filtriStatistiche/useFiltriStatistiche';

function Statistiche(props) {

    const {agenti} = useAgenti();
    const {filtriStatistiche, aggFiltriStatistiche} = useFiltriStatistiche();

    const [statistiche, setStatistiche] = useState({})
    const [altro, setAltro] = useState({errore:null, loading:true})
    const [segnalazioni, setSegnalazioni] = useState({lista:[], anno:[]})

    useEffect(() => {
        const aggSegnalazioni = async() => {
            setAltro({...altro, loading:true});
            //preparo le date
            let start = null, end = null;
            if(!filtriStatistiche.date[0].isFirst) {
                end = moment(filtriStatistiche.date[0].endDate).endOf('day')
			    start = moment(filtriStatistiche.date[0].startDate).startOf('day')
            } else {
                const now = moment();
                const year = now.get('year');
                start = moment({ year: year }).startOf('year')
                end = moment({ year: year }).endOf('year')
            }
            //richiama api
            const result = await segnalazioniApi.getStatistiche(start.toISOString(), end.toISOString());
            if(result.statusText != 'OK') return setAltro({...altro, errore:result.data.message});
            //agg segnalazioni
            setSegnalazioni({lista:result.data.reportings, anno:result.data.reportingsYear})
            aggStatistiche({lista:result.data.reportings, anno:result.data.reportingsYear})
        }
        if(filtriStatistiche.date[0]) aggSegnalazioni()
    }, [filtriStatistiche.date[0]])

    useEffect(() => {
        if(segnalazioni.lista.length) aggStatistiche(segnalazioni)
    }, [filtriStatistiche.agente, agenti])

    const aggStatistiche = (segnalazioni) => {
        //filtro per agente
        let lista = segnalazioni.lista;
        let listaYear = segnalazioni.anno;
        if(filtriStatistiche.agente) {
            lista = lista.filter((el) => el.agente == filtriStatistiche.agente);
            listaYear = listaYear.filter((el) => el.agente == filtriStatistiche.agente);
        }
        //calcolo quelle per anno
        let nYear = listaYear.length;
        let year = 2023
        if(!filtriStatistiche.date[0]?.isFirst) {
            let date = moment(filtriStatistiche.date[0].startDate.toISOString());
            year = date.get('year');
        } else {
            let now = moment();
            year = now.get('year');
        }
        //calcolo mese in corso
        let nMonth = 0, month = -1;
        if(filtriStatistiche.date[0]?.isFirst) {
            month = moment().get('month');
            let start = moment({month:month}).startOf('month');
            let end = moment({month:month}).endOf('month');
            nMonth = listaYear.filter((el) => {
                let date = moment(el.createdAt);
                if(date.isAfter(start) && date.isBefore(end)) return true;
                return false;
            }).length;
        }
        //calcolo periodo
        let nPeriod = 0;
        if(!filtriStatistiche.date[0]?.isFirst) nPeriod = lista.length;
        //calcolo migliori agenti
        let bestAgenti = [];
        if(!filtriStatistiche.agente) {
            bestAgenti = utils.calcolaBestAgenti(lista, agenti);
        }
        //calcolo migliori prodotti
        let bestProdotti = [];
        bestProdotti = utils.calcolaBestProdotti(lista);
        //agg statistiche
        setStatistiche({
            nYear, nMonth, nPeriod, bestAgenti,bestProdotti,
            actualYear:year, actualMonth:month
        })
        setTimeout(() => {
            setAltro({...altro, loading:false})
        }, 600);
    }

    const filtraSegnalazioni = useCallback(() => {
        if(!filtriStatistiche.agente) return segnalazioni.lista;
        return segnalazioni.lista.filter((el) => el.agente == filtriStatistiche.agente);
    }, [segnalazioni.lista, filtriStatistiche.agente])
    
    if(filtriStatistiche.date[0]) return (
        <Box sx={{padding:'24px 24px 24px'}}>
            <FiltriStatistiche
                date={filtriStatistiche.date}
                agente={filtriStatistiche.agente}
                listaAgenti={agenti}
                cambiaFiltri={aggFiltriStatistiche}
            ></FiltriStatistiche>

            <ZonaStatistiche
                visible={!altro.loading}
                date={filtriStatistiche.date}
                agente={filtriStatistiche.agente}
                listaAgenti={agenti}
                nYear={statistiche.nYear}
                nMonth={statistiche.nMonth}
                nPeriod={statistiche.nPeriod}
                bestAgenti={statistiche.bestAgenti}
                bestProdotti={statistiche.bestProdotti}
                actualYear={statistiche.actualYear}
                actualMonth={statistiche.actualMonth}
            ></ZonaStatistiche>

            {altro.loading &&
                <Box sx={{height:'300px', width:'100%', backgroundColor:'white', marginTop:'24px',
                    display:'flex', alignItems:'center', justifyContent:'center', border:'1px solid #dcdcdc'
                }}>
                    <CircularProgress sx={{width:'32px', height:'auto'}} color="primary" />
                </Box>
            }

            <GraficoSegnalazioni
                visible={!altro.loading}
                date={filtriStatistiche.date[0]}
                segnalazioni={filtraSegnalazioni()}
            ></GraficoSegnalazioni>
        </Box>
    );
}

export default Statistiche;