import { JWT_TOKEN } from "../config";
import Storage from '../libs/storage';
import axios from "axios";

const apiClient = axios.create({
  baseURL: ""
});

apiClient.interceptors.request.use(config => {
  const token = Storage.getItem(JWT_TOKEN);
  if (token) config.headers.Authorization = "Bearer " + token;
  return config;
});

export default apiClient;