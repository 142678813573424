import { useContext } from "react";

import AgentiContext from "./context";

const useAgenti = () => {

    const { agenti, setAgenti } = useContext(AgentiContext);

    const aggAgenti = (v) => setAgenti({...agenti, lista:v})
    const aggLoading = (v) => setAgenti({...agenti, loading:v})

    return { 
        agenti: agenti?.lista,
        agentiLoading: agenti?.loading,

        aggAgenti,
        aggLoading
    }
}

export default useAgenti;