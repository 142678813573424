import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import NumeroPage from './NumeroPage';

function TablePagination({pages, pageAttuale, prev, next, premiPagina}) {

    //GESTIRE IL DEVE COMPARIRE / SELEZIONATO ...

    return (
        <Box sx={{maxWidth:'100%', backgroundColor:'white', border:'1px solid #dcdcdc', 
            borderTop:'none', borderBottomLeftRadius:'6px', borderBottomRightRadius:'6px',
            padding:'12px 16px', display:'flex', flexDirection:'row', justifyContent:'space-between'
        }}>
            <Button
                color="secondary"
                variant="contained"
                startIcon={<WestIcon sx={{width:'20px', height:'auto', marginLeft:'6px'}} />}
                sx={{
                    border:'1px solid #dcdcdc', padding:'6px 8px', textTransform: 'none',
                    fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'-0.2', textAlign:'center', color:'black', whiteSpace:'nowrap'
                }}
                disabled={pageAttuale === 1}
                disableElevation={true}
                onClick={prev}
            >Precedente</Button>

            <Stack direction={'row'} spacing='0px'>
                {pages.map((elemento, index) => (
                    <NumeroPage
                        key={index}
                        num={elemento}
                        selected={(index + 1) === pageAttuale}
                        onClick={() => premiPagina(elemento)}
                    ></NumeroPage>
                ))}
            </Stack>

            <Button
                color="secondary"
                variant="contained"
                endIcon={<EastIcon sx={{width:'20px', height:'auto', marginLeft:'6px'}} />}
                sx={{
                    border:'1px solid #dcdcdc', padding:'6px 8px', textTransform: 'none',
                    fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'-0.2', textAlign:'center', color:'black', whiteSpace:'nowrap'
                }}
                disabled={pageAttuale == pages.length || pages.length == 0}
                disableElevation={true}
                onClick={next}
            >Successivo</Button>
        </Box>
    );
}

export default TablePagination;