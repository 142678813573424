import client from "./client";
import { API_URL } from "../config";

const endpoint = API_URL + '/users/'

const getAgenti = async() => {
    try {
        const result = await client.get(endpoint + 'list')
        return result
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

export default {
    getAgenti
}
