
const calcolaBestAgenti = (lista, agenti) => {
    let result = [];
    //conteggio la lista
    for(let el of lista) {
        let pos = result.findIndex((res) => res.agente == el.agente);
        if(pos >= 0) {
            result[pos].cont += 1;
        } else {
            if(!el.agente?.length) continue;
            result.push({agente:el.agente, cont:1})
        }
    }
    //assegno il nome
    result = result.map((el) => {
        let appo = agenti.find((ag) => ag.id == el.agente);
        if(appo) el.fullName = appo.fullName;
        else el.fullName = 'N/A';
        return el;
    });
    //ordino e ritorno la lista
    return result.sort((a, b) => {
        if(a.cont > b.cont) return -1;
        if(a.cont < b.cont) return 1;
        return 0;
    });
}

const calcolaBestProdotti = (lista) => {
    const result = [];
    //conteggio la lista
    for(let el of lista) {
        let pos = result.findIndex((res) => res.code == el.codiceProdotto);
        if(pos >= 0) {
            result[pos].cont += 1;
        } else {
            if(!el.codiceProdotto?.length && !el.nomeProdotto?.length) continue;
            result.push({code:scriviNomeProdotto(el), cont:1})
        }
    }
    //ordino e ritorno la lista
    return result.sort((a, b) => {
        if(a.cont > b.cont) return -1;
        if(a.cont < b.cont) return 1;
        return 0;
    });
}

const scriviNomeProdotto = (prod) => {
    let result = '';
    if(prod.nomeProdotto?.length) {
        result += prod.nomeProdotto.slice(0, 40);
        if(prod.nomeProdotto.length > 40) result += '...';
    }
    if(prod.codiceProdotto?.length) {
        if(prod.nomeProdotto?.length) result += ' ('
        result += prod.codiceProdotto;
        if(prod.nomeProdotto?.length) result += ')'
    }
    return result;
}

const scriviMese = (mese) => {
    const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
        'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
    return mesi[mese];
}

const scriviMeseAbbr = (mese) => {
    const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
        'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
    return mesi[mese].slice(0, 3);
}

export default {
    scriviMese,
    scriviMeseAbbr,
    calcolaBestAgenti,
    calcolaBestProdotti
}