import { useContext } from "react";

import SegnalazioniContext from "./context";

const useSegnalazioni = () => {

    const { segnalazioni, setSegnalazioni } = useContext(SegnalazioniContext);

    const aggSegnalazioni = (v) => setSegnalazioni({...segnalazioni, lista:v})
    const aggLoading = (v) => setSegnalazioni({...segnalazioni, loading:v})

    const aggSegnalazione = (segn) => {
        const appo = segnalazioni.lista.map((el) => {
            if(el.id == segn.id) return segn;
            return el;
        })
        setSegnalazioni({...segnalazioni, lista:appo})
    }

    return { 
        segnalazioni: segnalazioni?.lista,
        segnalazioniLoading: segnalazioni?.loading,

        aggSegnalazioni,
        aggSegnalazione,
        aggLoading
    }
}

export default useSegnalazioni;