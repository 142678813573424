import { useContext } from "react";

import FiltriContext from "./context";

const useFiltri = () => {

    const { filtri, setFiltri } = useContext(FiltriContext);

    const aggFiltri = (values) => setFiltri({...values})
    const aggPage = (v) => setFiltri({...filtri, page:v})

    return { 
        filtri: filtri,
        page: filtri.page,

        aggFiltri,
        aggPage
    }
}

export default useFiltri;