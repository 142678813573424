import React, {useState, useEffect} from 'react';
import moment from 'moment/moment';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';

import CellaCorpo from './CellaCorpo';
import CellaHeader from './CellaHeader';
import TablePagination from './TablePagination';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

function TabellaSegnalazioni({
    segnalazioni, segnalazioniLoading, page, aggPage, premiSegnalazione, clickPreferito
}) {

    const rowsPerPage = 10
    const [pagine, setPagine] = useState([])

    useEffect(() => {
        const handlePage = () => {
            let valore = parseInt(segnalazioni.length / rowsPerPage);
            let resto = segnalazioni.length % rowsPerPage;
            if(resto) valore++
            const appo = creaArrayPages(valore);
            setPagine(appo)
            if(page > appo.length) aggPage(1)
        }
        handlePage()
    }, [segnalazioni])

    const creaArrayPages = (x) => {
        var array = [];
        for (var i = 1; i <= x; i++) {
            array.push(i);
        }
        return array;
    }
    
    return (
        <TableContainer sx={{height:'fit-content', marginTop:'16px'}}>
            <Table stickyHeader aria-label="sticky table">
                
                <TableHead>
                    <TableRow>
                        <CellaHeader
                            text={'Data'}
                            Icon={AccessTimeIcon}
                            align={'left'}
                            borderLeft={true}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Cliente'}
                            Icon={PersonOutlineOutlinedIcon}
                            align={'left'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Competitor'}
                            Icon={VisibilityOutlinedIcon}
                            align={'left'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Prodotto'}
                            Icon={Inventory2OutlinedIcon}
                            align={'left'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Codice Prodotto'}
                            Icon={CodeRoundedIcon}
                            align={'left'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Prezzo'}
                            Icon={EuroRoundedIcon}
                            align={'left'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Foto'}
                            align={'center'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Stato'}
                            align={'center'}
                        ></CellaHeader>
                        <CellaHeader
                            text={'Preferiti'}
                            align={'center'}
                        ></CellaHeader>
                        <CellaHeader
                            width={'50px'}
                            text={''}
                            align={'center'}
                            borderRight={true}
                        ></CellaHeader>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {!segnalazioniLoading && segnalazioni.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((segnalazione, index) => (
                        <TableRow key={index}>
                            <CellaCorpo
                                align={'left'}
                                borderLeft={true}
                                value={moment(segnalazione.createdAt).format('DD/MM/YYYY HH:mm')}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'left'}
                                value={segnalazione.cliente}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'left'}
                                value={segnalazione.competitor}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'left'}
                                value={segnalazione.nomeProdotto}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'left'}
                                value={segnalazione.codiceProdotto}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'center'}
                                value={segnalazione.prezzo > 0 ? parseFloat(segnalazione.prezzo).toFixed(2) + '€' : '-'}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'center'}
                                isFoto={true}
                                value={segnalazione.photo?.length > 0}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'center'}
                                isStato={true}
                                value={segnalazione.status}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'center'}
                                isPreferiti={true}
                                onClick={() => clickPreferito(segnalazione)}
                                value={segnalazione.preferred}
                            ></CellaCorpo>
                            <CellaCorpo
                                align={'center'}
                                isAction={true}
                                borderRight={true}
                                value={'modifica'}
                                onClick={() => premiSegnalazione(segnalazione)}
                            ></CellaCorpo>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {!segnalazioniLoading && segnalazioni.length == 0 &&
                <Box sx={{height:'300px', width:'100%', backgroundColor:'white',
                    display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid #dcdcdc'
                }}>
                    <Typography sx={{
                        fontSize:'17px', fontWeight:'500', lineHeight:'normal', 
                        letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Non ci sono segnalazioni con questi filtri!</Typography>
                </Box>
            }
            {segnalazioniLoading &&
                <Box sx={{height:'300px', width:'100%', backgroundColor:'white',
                    display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid #dcdcdc'
                }}>
                    <CircularProgress sx={{width:'32px', height:'auto'}} color="primary" />
                </Box>
            }
            <TablePagination
                pages={pagine}
                pageAttuale={page}
                next={() => aggPage(page + 1)}
                prev={() => aggPage(page - 1)}
                premiPagina={(p) => aggPage(p)}
            ></TablePagination>
        </TableContainer>
    );
}

export default TabellaSegnalazioni;