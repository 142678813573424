import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';

import CloseIcon from '@mui/icons-material/Close';

const Backdrop = styled('div')({
    position: 'fixed', top: 0,
    right: 0, bottom: 0, left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

function ModalAgenti({open, agenti, value, cambiaValue, onClose, pulisciFiltro}) {

    const [agenteInterno, setAgenteInterno] = useState(null)

    useEffect(() => {
        const aggAgenteInterno = () => {
            setAgenteInterno(value)
        }
        aggAgenteInterno()
    }, [value])

    return (<>
        <Backdrop style={{display:open ? 'block' : 'none', zIndex:3000}} onClick={onClose} />
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition keepMounted
            sx={{display:'flex', width:'100%', height:'100vh', alignItems:'center', justifyContent:'center', zIndex:'4000'}}
        >
            <Fade in={open}>
                <Box sx={{width:'fit-content', backgroundColor:'white', borderRadius:'6px', 
                    border:'1px solid #dcdcdc', overflow:'hidden'
                }}>
                    <Box sx={{width:'500px', backgroundColor:'#EDE3FC', display:'flex', alignItems:'center',
                        justifyContent:'space-between', padding:'12px 24px', boxSizing:'border-box'
                    }}>
                        <Typography component='span' sx={{fontSize:'14px', fontWeight:'500',
                            lineHeight:'normal', letterSpacing:'normal', color:'black'
                        }}>Seleziona l'agente che preferisci</Typography>
                        <IconButton onClick={onClose} sx={{
                            width:'26px', height:'26px', borderRadius:'26px',
                            display:'flex', alignItems:'center', justifyContent:'center'
                        }}>
                            <CloseIcon sx={{color:'black'}} />
                        </IconButton>
                    </Box>

                    <Box sx={{width:'100%', height:'400px', overflowY:'scroll'}}>
                        <List component="nav" aria-label="main filters order" sx={{margin:0, padding:0}}>
                            {agenti.map((agente, index) => (
                                <ListItemButton
                                    key={index}
                                    selected={agente.id == agenteInterno}
                                    sx={{height:'50px', paddingLeft:'24px', display:'flex', alignItems:'center',
                                        borderBottom:index < agenti.length - 1 ? '1px solid #dcdcdc' : 'none'
                                    }}
                                    onClick={() => setAgenteInterno(agente.id)}
                                >
                                    <Box sx={{width:'14px', height:'14px', borderRadius:'14px',
                                        border:'1px solid #dcdcdc', marginRight:'9px',
                                        backgroundColor:agente.id == agenteInterno ? 'primary.main' : 'transparent'
                                    }} />
                                    <Typography component='span' sx={{fontSize:'14px', fontWeight:'500',
                                        lineHeight:'normal', letterSpacing:'normal', color:'black'
                                    }}>{agente.fullName}</Typography>
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>

                    <Box sx={{width:'500px', backgroundColor:'white', display:'flex', alignItems:'center',
                        justifyContent:'space-between', padding:'12px 24px', boxSizing:'border-box', borderTop:'1px solid #dcdcdc'
                    }}>
                        <Button
                            color="rosso"
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', padding:'9px 24px', textTransform: 'none',
                                fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'center', whiteSpace:'nowrap'
                            }}
                            disableElevation={true}
                            onClick={() => {pulisciFiltro(); onClose();}}
                        >Annulla</Button>
                        <Button
                            color="verde"
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', padding:'9px 24px', textTransform: 'none',
                                fontSize:'13px', fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'center', whiteSpace:'nowrap'
                            }}
                            disableElevation={true}
                            onClick={() => {cambiaValue(agenteInterno); onClose();}}
                        >Applica Filto</Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
}

export default ModalAgenti;