import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

import utils from '../libs/utils';

function GraficoSegnalazioni({visible, segnalazioni, date}) {

    const [dati, setDati] = useState([])

    useEffect(() => {
        const aggDati = () => {
            if(dati.length) setDati([])
            if(date.isFirst) return aggDatiMensili();
            const start = moment(date.startDate);
            const end = moment(date.endDate);
            const diff = end.diff(start, 'days');
            if(diff > 360) return aggDatiMensili();
            if(diff < 61) return aggDatiGiornalieri();
            return aggDatiSettimanali();
        }
        aggDati()
    }, [segnalazioni])

    const aggDatiMensili = () => {
        const result = []
        //calcolo data di inizio
        let appo = (date.startDate && !date.isFirst) ? moment(date.startDate) : moment({year:moment().get('year')}).startOf('year');
        const start = moment({month:appo.get('month')}).startOf('month');
        //calcolo data di fine
        appo = (date.endDate && !date.isFirst) ? moment(date.endDate) : moment({year:moment().get('year')}).endOf('year');
        const end = moment({month:appo.get('month')}).endOf('month');
        //scorro i vari mesi
        let dataAttuale = start, dataFinale = null, cont = 0;
        while(dataAttuale.isBefore(end)) {
            //preparo la data fine ciclo attuale
            dataFinale = moment(dataAttuale.toISOString());
            dataFinale.add({month:1});
            //inserisco la statistica
            cont = segnalazioni.filter((segn) => {
                let createdAt = moment(segn.createdAt);
                return createdAt.isBetween(dataAttuale, dataFinale);
            }).length;
            result.push({
                name: utils.scriviMese(dataAttuale.get('month')) + ' ' + dataAttuale.format('YYYY'),
                segnalazioni:cont
            });
            //prossimo ciclo
            dataAttuale.add({month:1})
        }
        //aggiorno i dati del grafico
        setDati(result);
    }

    const aggDatiSettimanali = () => {
        const result = []
        //calcolo data di inizio
        let appo = (date.startDate && !date.isFirst) ? moment(date.startDate) : moment({year:moment().get('year')}).startOf('year');
        const start = moment({month:appo.get('month')}).startOf('week').add({days:1});
        //calcolo data di fine
        appo = (date.endDate && !date.isFirst) ? moment(date.endDate) : moment({year:moment().get('year')}).endOf('year');
        const end = moment({month:appo.get('month')}).add({weeks:2}).endOf('week').add({days:1});
        //scorro i vari mesi
        let dataAttuale = start, dataFinale = null, cont = 0;
        while(dataAttuale.isBefore(end)) {
            //preparo la data fine ciclo attuale
            dataFinale = moment(dataAttuale.toISOString());
            dataFinale.add({week:1});
            //inserisco la statistica
            cont = segnalazioni.filter((segn) => {
                let createdAt = moment(segn.createdAt);
                return createdAt.isBetween(dataAttuale, dataFinale.add({hours:1}));
            }).length;
            result.push({
                name:dataAttuale.format('DD/MM') + '-' + dataFinale.format('DD/MM'),
                segnalazioni:cont
            });
            //prossimo ciclo
            dataAttuale.add({week:1})
        }
        //aggiorno i dati del grafico
        setDati(result);
    }

    const aggDatiGiornalieri = () => {
        const result = []
        //calcolo data di inizio
        let appo = (date.startDate && !date.isFirst) ? moment(date.startDate) : moment({year:moment().get('year')}).startOf('year');
        const start = moment({month:appo.get('month')}).startOf('month');
        //calcolo data di fine
        appo = (date.endDate && !date.isFirst) ? moment(date.endDate) : moment({year:moment().get('year')}).endOf('year');
        const end = moment(appo).add({days:2});
        //scorro i vari mesi
        let dataAttuale = start, dataFinale = null, cont = 0;
        while(dataAttuale.isBefore(end)) {
            //preparo la data fine ciclo attuale
            dataFinale = moment(dataAttuale.toISOString());
            dataFinale.add({days:1});
            //inserisco la statistica
            cont = segnalazioni.filter((segn) => {
                let createdAt = moment(segn.createdAt);
                return createdAt.isBetween(dataAttuale, dataFinale);
            }).length;
            result.push({
                name:dataAttuale.format('DD/MM'),
                segnalazioni:cont
            });
            //prossimo ciclo
            dataAttuale.add({days:1})
        }
        //aggiorno i dati del grafico
        setDati(result);
    }
    
    if(dati.length) return (
        <Box sx={{width:'100%', marginTop:'24px', border:'1px solid #dcdcdc', borderRadius:'6px',
            boxSizing:'border-box', backgroundColor:'white', height:'fit-content',
            display:visible ? 'flex' : 'none', flexDirection:'row', padding:'36px 16px 24px'
        }}>
            <Box sx={{width:'30px', height:360, display:'flex',
                alignItems:'center', justifyContent:'center', marginRight:'-12px'
            }}>
                <Typography sx={{fontSize:'14px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', transform: 'rotate(-90deg)'
                }}>Segnalazioni</Typography>
            </Box>

            <BarChart
                width={window.innerWidth - 150}
                height={360}
                data={dati}
                barSize={32}
            >
                <CartesianGrid
                    stroke="#ECE5E0"
                    strokeWidth={0.8}
                    strokeDasharray="0"
                    horizontal={true}
                    vertical={false}
                />
                <Tooltip />
                
                <YAxis
                    tickLine={null} tickMargin={12}
                    axisLine={{stroke: "#ECE5E0", strokeWidth:0}}
                    tick={{fill:"black", fontSize:16, fontWeight:"600"}}
                    allowDecimals={false} allowDataOverflow={false}
                />
                <XAxis
                    interval={dati.length <= 20 ? 0 : 1}
                    dataKey="name" scale="point" tickMargin={12}
                    tickLine={null} padding={{left:40, right:50}}
                    axisLine={{stroke: "#ECE5E0", strokeWidth:0}}
                    tick={{fill:"black", fontSize:12, fontWeight:"500"}}
                    style={{ overflow: "visible" }}
                />

                <Bar dataKey="segnalazioni" fill="#90a9ff" barSize={32} radius={[6, 6, 0, 0]}
                    activeBar={<Rectangle fill="#1d00cf" stroke="#1d00cf" />} 
                />
            </BarChart>
        </Box>
    );
}

export default GraficoSegnalazioni;