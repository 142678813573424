import React, { useCallback, useEffect, useState } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';

import Filtri from '../components/Filtri';
import AppAlert from '../components/AppAlert';
import useAuth from '../controllers/auth/useAuth';
import useDate from '../controllers/date/useDate';
import segnalazioniApi from '../api/segnalazioni';
import useAgenti from '../controllers/agenti/useAgenti';
import useFiltri from '../controllers/filtri/useFiltri';
import TabellaSegnalazioni from '../components/TabellaSegnalazioni';
import useSegnalazioni from '../controllers/segnalazioni/useSegnalazioni';

function HomePage(props) {

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const {user} = useAuth()
    const {agenti} = useAgenti()
    const {date, aggDate} = useDate()
    const {filtri, page, aggFiltri, aggPage} = useFiltri()
    const {segnalazioni, segnalazioniLoading, aggSegnalazione} = useSegnalazioni()

    const [altro, setAltro] = useState({errore:null, loading:false, alert:0})

    useEffect(() => {
        const aggAlert = () => {
            if(state?.modifica) {
                setAltro({...altro, alert:1})
                navigate(pathname, {state:null})
            }
        }
        aggAlert()
    }, [state])

    const filtraSegnalazioni = useCallback(() => {
        let lista = [...segnalazioni]
        //filtro per agente e ricerca
        if(filtri.ricerca.length > 0 || filtri.agente) {
            lista = lista.filter((el) => {
                if(filtri.agente) { //agente
                    if(el.agente != filtri.agente) return false;
                }
                if(filtri.ricerca.length > 0) { //ricerca
                    let cerca = filtri.ricerca.toLowerCase();
                    let trovato = false;
                    if(el.cliente.toLowerCase().includes(cerca)) trovato = true;
                    if(el.competitor.toLowerCase().includes(cerca)) trovato = true;
                    if(el.nomeProdotto.toLowerCase().includes(cerca)) trovato = true;
                    if(el.codiceProdotto.toLowerCase().includes(cerca)) trovato = true;
                    if(!trovato) return false;
                }
                return true;
            })
        }
        //ordino la lista
        if(filtri.ordina > 0) {
            lista = lista.sort((a, b) => {
                if(filtri.ordina === 1) {
                    const dataA = moment(a.createdAt);
                    const dataB = moment(b.createdAt);
                    if(dataA.isBefore(dataB)) return -1;
                    if(dataA.isAfter(dataB)) return 1;
                    return 0;
                } else if(filtri.ordina === 2) {
                    if(!a.status && b.status) return -1;
                    if(a.status && !b.status) return 1;
                    return 0;
                } else {
                    if(a.preferred && !b.preferred) return -1;
                    if(!a.preferred && b.preferred) return 1;
                    return 0;
                }
            })
        }
        //ritorno la lista filtrata
        return lista;
    }, [segnalazioni, filtri])

    const clickPreferito = async(segnalazione) => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        //chiamo le api
        let result = await segnalazioniApi.segnalazionePreferita(
            segnalazione.id, !segnalazione.preferred
        );
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        //correct
        setTimeout(() => {
            aggSegnalazione({...result.data})
            setAltro({
                ...altro, errore:null, loading:false, 
                alert: segnalazione.preferred ? 3 : 2
            })
        }, 300);
    }

    const esportaSegnalazioni = () => {
        //preparo i dati da esportare
        const intestazione = 'id;agente;cliente;competitor;nome prodotto;codice prodotto;prezzo;data;commento';
        const dati = segnalazioni.map((segn) => {
            let result = '';
            let agente = agenti.filter((el) => el.id == segn.agente)[0];
            result += (segn.cont ? segn.cont : '') + ';';
            result += (agente ? agente.fullName : '') + ';';
            result += (segn.cliente?.length ? segn.cliente.trim() : '') + ';';
            result += (segn.competitor?.length ? segn.competitor.trim() : '') + ';';
            result += (segn.nomeProdotto?.length ? segn.nomeProdotto.trim() : '') + ';';
            result += (segn.codiceProdotto?.length ? segn.codiceProdotto.trim() : '') + ';';
            result += (segn.prezzo ? parseFloat(segn.prezzo).toFixed(2) : '') + ';';
            result += (segn.createdAt?.length ? moment(segn.createdAt).format("DD/MM/YYYY-HH:mm") : '') + ';';
            result += (segn.commento?.length ? segn.commento.trim() : '') + ';';
            return result;
        });
        dati.unshift(intestazione);
        const fileData = dati.join('\n');
        //preparo il file
        const url = window.URL.createObjectURL(new Blob([fileData]));
        const link = document.createElement('a');
        link.href = url;
        //scateno l'evento
        const fileName = `Report-Segnalazioni-${moment(new Date()).format("DD/MM/YYYY-HH:mm")}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, alert:0})
    }
    
    if(user) return (
        <Box sx={{padding:'16px 24px 24px'}}>
            <Filtri
                date={date}
                agenti={agenti}
                filtri={filtri}
                cambiaDate={aggDate}
                cambiaFiltri={aggFiltri}
                esportaSegnalazioni={esportaSegnalazioni}
            ></Filtri>
            <TabellaSegnalazioni
                page={page}
                aggPage={aggPage}
                clickPreferito={clickPreferito}
                segnalazioni={filtraSegnalazioni()}
                segnalazioniLoading={segnalazioniLoading}
                premiSegnalazione={(s) => navigate('/modifica-segnalazione', {state:{segnalazione:s}})}
            ></TabellaSegnalazioni>

            <AppAlert
                open={altro.alert === 1}
                severity={'success'}
                chiudiAlert={chiudiAlert}
                message={'Segnalazione modificata con successo!'}
            ></AppAlert>
            <AppAlert
                open={altro.alert === 2}
                severity={'success'}
                chiudiAlert={chiudiAlert}
                message={'Segnalazione aggiunta ai preferiti!'}
            ></AppAlert>
            <AppAlert
                open={altro.alert === 3}
                severity={'success'}
                chiudiAlert={chiudiAlert}
                message={'Segnalazione rimossa dai preferiti!'}
            ></AppAlert>
            <AppAlert
                open={Boolean(altro.errore?.length)}
                severity={'error'}
                chiudiAlert={chiudiAlert}
                message={altro.errore}
            ></AppAlert>
        </Box>
    );
}

export default HomePage;