import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function BottoneFiltro({text, onClick, Icon, selected}) {

    return (
        <Button
            color={selected ? "primary" : "secondary"}
            variant="contained"
            startIcon={Icon}
            sx={{border:'1px solid #dcdcdc', padding:'6px 24px', textTransform: 'none', minWidth:'120px', borderRadius:'4px'}}
            disableElevation={true}
            onClick={onClick}
        >
            <Typography 
                noWrap
                sx={{
                    fontSize:'13px', fontWeight:'500', lineHeight:'normal', whiteSpace:'nowrap', 
                    letterSpacing:'-0.2', textAlign:'center', color:selected ? 'white' : 'black'
                }}
            >
                {text}
            </Typography>
        </Button>
    );
}

export default BottoneFiltro;