import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';

import CloseIcon from '@mui/icons-material/Close';

const Backdrop = styled('div')({
    position: 'fixed', top: 0,
    right: 0, bottom: 0, left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

function ModalFoto({img, open, onClose}) {

    return (<>
        <Backdrop style={{display:open ? 'block' : 'none', zIndex:3000, cursor:'pointer'}} onClick={onClose} />
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition keepMounted
            sx={{display:'flex', width:'100%', height:'100vh', alignItems:'center',
                justifyContent:'center', zIndex:'4000', cursor:'pointer'
            }}
        >
            <Fade in={open}>
                <Box sx={{width:'fit-content', borderRadius:'6px', 
                    border:'1px solid #dcdcdc', overflow:'hidden', cursor:'default'
                }}>
                    <Box
                        component='img' alt='' src={img}
                        sx={{width:'auto', minHeight:'50vh', maxHeight:'85vh'}}
                    />
                </Box>
            </Fade>
        </Modal>
    </>);
}

export default ModalFoto;