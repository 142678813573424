import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './Login';
import Account from './Account';
import HomePage from './HomePage';
import Statistiche from './Statistiche';
import Header from '../components/Header';
import Segnalazione from './Segnalazione';
import ModificaPassword from './ModificaPassword';
import DimenticaPassword from './DimenticaPassword';

function Navigator(props) {

    return (
        <BrowserRouter>
            <Routes>

                {/* BACKEND */}
                <Route path="/" element={<Header />}>

                    <Route index element={<HomePage />} />
                    <Route path="/modifica-segnalazione" element={<Segnalazione />} />

                    <Route path="/account" element={<Account />} />
                    <Route path="/statistiche" element={<Statistiche />} />
                    <Route path="/modifica-password" element={<ModificaPassword />} />

                </Route>

                {/* AUTH */}
                <Route path="/login" element={<Login />} />
                <Route path="/password-dimenticata" element={<DimenticaPassword />} />

            </Routes>
        </BrowserRouter>
    );
}

export default Navigator;