import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AppAlert from '../components/AppAlert';
import useAuth from '../controllers/auth/useAuth';
import CampoAccount from '../components/CampoAccount';

function Account(props) {

    const {user, logout} = useAuth()

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const [alert, setAlert] = useState(false)

    useEffect(() => {
        const aggAlert = () => {
            setAlert(true);
            navigate(pathname, {state:null});
        }
        if(state?.isPwd) aggAlert()
    }, [state])

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAlert(false)
    }
    
    return (
        <Box sx={{width:'100%', padding:'80px 0', backgroundColor:'#f9f9f9', 
            overflow:'auto', display:'flex', justifyContent:'center', alignItems:'center'
        }}>
            <Stack direction='column' spacing='30px' sx={{paddingBottom:'80px', width:'400px'}}>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Typography sx={{fontSize:'36px', fontWeight:'500', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Il mio Account</Typography>
                    <Typography sx={{paddingTop:'6px', paddingBottom:'4px', fontSize:'14px', fontWeight:'400', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#96A0AD'
                    }} dangerouslySetInnerHTML={{__html: "In questa sezione puoi modificare<br/> la tua password o effettuare il logout"}}></Typography>
                </Box>

                <CampoAccount
                    value={user?.fullName}
                    name={'Nominativo'}
                ></CampoAccount>
                <CampoAccount
                    value={user?.email}
                    name={'Email'}
                ></CampoAccount>
                <CampoAccount
                    value={'*********'}
                    name={'Password'}
                ></CampoAccount>

                <Stack direction='row' spacing='16px' sx={{width:'400px', justifyContent:'space-between'}}>
                    <Button
                        color={"primary"}
                        variant="contained"
                        sx={{border:'none', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                        disableElevation={true}
                        onClick={() => navigate('/modifica-password')}
                    >
                        <Typography noWrap component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                            letterSpacing:'-0.2', textAlign:'left', color:'white'
                        }}>Modifica Password</Typography>
                    </Button>
                    <Button
                        color={"nero"}
                        variant="contained"
                        sx={{border:'none', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                        disableElevation={true}
                        onClick={() => {
                            logout()
                            navigate('/login')
                        }}
                    >
                        <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                            letterSpacing:'-0.2', textAlign:'left', color:'white'
                        }}>Logout</Typography>
                    </Button>
                </Stack>
            </Stack>

            <AppAlert
                open={alert}
                severity={'success'}
                chiudiAlert={chiudiAlert}
                message={'Password modificata con successo!'}
            ></AppAlert>
        </Box>
    );
}

export default Account;