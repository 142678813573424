import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

function AppAlert({open, chiudiAlert, severity, message}) {

    const [msg, setMsg] = useState(message)

    useEffect(() => {
        const aggMsg = () => {
            if(message) setMsg(message)
            else setTimeout(() => {
                setMsg(null)
            }, 500);
        }
        aggMsg()
    }, [message])

    return (
        <Snackbar 
            open={open} 
            onClose={chiudiAlert} 
            autoHideDuration={3000}
            TransitionComponent={Slide}
            transitionDuration={500}
            anchorOrigin={{vertical: "top", horizontal: "right"}} 
            sx={{top:'110px', right:'150px', left:'auto'}}
        >
            <Alert
                variant="standard" 
                severity={severity}
                sx={{width:'100%', height:'auto', padding:{xs:'5px 10px 4px', sm:'4px 10px'}}}
                onClose={chiudiAlert}
                style={{
                    border:severity == 'error' ? '1px solid #DE3163' : (
                        severity == 'warning' ? '1px solid orange' : '1px solid green'
                    ), borderRadius:'12px'
                }}
            >
                <Typography sx={{padding:'0 6px',
                    textAlign:'center', letterSpacing:'-0.2', color:'black',
                    fontSize:'13px', fontWeight:'400', lineHeight:'normal'
                }}>{msg}</Typography>
            </Alert>
        </Snackbar>
    );
}

export default AppAlert;