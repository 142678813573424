import React, {useRef, useEffect} from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import Logo from '../images/logo.png';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

function Header(props) {

    const scrollRef = useRef()

    const navigate = useNavigate()
    const {pathname, state} = useLocation()

    useEffect(() => {
        scrollRef?.current?.scrollTo(0, 0)
    }, [pathname, state])
    
    return (
        <Box sx={{width:'100%', height:'100vh', overflow:'hidden'}}>
            <AppBar
                color='bianco'
                position="static" 
                elevation={0}
                sx={{borderBottom:'1px solid #dcdcdc', p:0, m:0, height:'60px', zIndex:9999}}
            >
                <Toolbar disableGutters sx={{
                    width:'calc(100% - 40px)', p:'0 20px',
                    height:'100%', m:0, alignItems:'center', justifyContent:'space-between'
                }}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Box component='img' alt='' src={Logo} sx={{
                            width:'auto', height:'45px', objectFit:'contain', cursor:'pointer'
                        }} onClick={() => navigate('/')} />

                        <Stack direction='row' spacing='30px' sx={{marginLeft:'60px'}}>
                            <Button
                                color="secondary"
                                variant="contained"
                                sx={{
                                    border:'1px solid #dcdcdc', padding:'7px 16px', textTransform: 'none',
                                    fontSize:'14px', fontWeight:'500', lineHeight:'normal', 
                                    letterSpacing:'-0.2', textAlign:'center', color:'black',
                                }}
                                disableElevation={true}
                                onClick={() => navigate('/')}
                            >Home</Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                sx={{
                                    border:'1px solid #dcdcdc', padding:'7px 16px', textTransform: 'none',
                                    fontSize:'14px', fontWeight:'500', lineHeight:'normal', 
                                    letterSpacing:'-0.2', textAlign:'center', color:'black',
                                }}
                                disableElevation={true}
                                onClick={() => navigate('/statistiche')}
                            >Statistiche</Button>
                        </Stack>
                    </Box>

                    <IconButton sx={{width:'36px', height:'36px', borderRadius:'25px',
                        backgroundColor:'secondary.main', border:'1px solid #dcdcdc'
                    }} onClick={() => navigate('/account')}>
                        <PersonOutlineIcon sx={{width:'24px', height:'auto', color:'black'}} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box sx={{width:'100%', overflow:'auto', backgroundColor:'#f9f9f9',
                position:'relative', height:'calc(100vh - 60px)'
            }} ref={scrollRef}>
                <Outlet />
            </Box>
        </Box>
    );
}

export default Header;