import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import authApi from '../api/auth'
import AppAlert from '../components/AppAlert'
import FormPassword from '../components/FormPassword'
import FormActivation from '../components/FormActivation'

import Logo from '../images/logo.png'

function DimenticaPassword(props) {

    const navigate = useNavigate()

    const [success, setSuccess] = useState(false)
    const [altro, setAltro] = useState({errore:null, loading:false, openAlert:0})

    const conferma = async(valori) => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        const result = await authApi.forgotPassword({email: valori.email})
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        setTimeout(() => {
            setAltro({...altro, errore:null, loading:false, openAlert:1})
            setSuccess(true)
        }, 300);
    }

    const confermaCambioPassword = async(valori) => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        const result = await authApi.resetPassword({
            email:valori.email, password:valori.password,
            passwordResetToken:valori.token
        })
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        setTimeout(() => {
            setAltro({...altro, errore:null, loading:false})
            navigate('/login', {state:{isCambioPwd:true}})
        }, 300);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, openAlert:0})
    }

    if(!success) return (
        <Box sx={{width:'100%', minHeight:'99.9vh', backgroundColor:'#f9f9f9', 
            overflow:'auto', display:'flex', justifyContent:'center', alignItems:'center'
        }}>
            <Stack direction='column' spacing='30px' sx={{alignItems:'center', paddingBottom:'80px'}}>
                <Box sx={{display:'flex', alignItems:'center', margin:'50px 0 0'}}>
                    <Box component='img' alt='' src={Logo} sx={{
                        width:'auto', height:'110px', objectFit:'contain'
                    }} />
                </Box>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Typography sx={{fontSize:'36px', fontWeight:'500', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Recupera la Password</Typography>
                    <Typography sx={{paddingTop:'6px', paddingBottom:'4px', fontSize:'14px', fontWeight:'400', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#96A0AD'
                    }} dangerouslySetInnerHTML={{__html: "Recupera la tua password <br/>ed effettua nuovamente il login"}}></Typography>
                </Box>
                <FormActivation
                    isEmail={true}
                    conferma={conferma}
                    errore={altro.errore}
                    loading={altro.loading}
                    textButton={'Invia codice'}
                    cambiaSuccess={setSuccess}
                ></FormActivation>
            </Stack>
        </Box>
    );
    
    return (
        <Box sx={{width:'100%', minHeight:'99.9vh', backgroundColor:'#f9f9f9', 
            overflow:'auto', display:'flex', justifyContent:'center', alignItems:'center'
        }}>
            <Stack direction='column' spacing='30px' sx={{alignItems:'center', paddingBottom:'80px'}}>
                <Box sx={{display:'flex', alignItems:'center', margin:'50px 0 0'}}>
                    <Box component='img' alt='' src={Logo} sx={{
                        width:'auto', height:'110px', objectFit:'contain'
                    }} />
                </Box>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Typography sx={{fontSize:'36px', fontWeight:'500', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Modifica Password</Typography>
                    <Typography sx={{paddingTop:'6px', paddingBottom:'4px', fontSize:'14px', fontWeight:'400', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#96A0AD'
                    }} dangerouslySetInnerHTML={{__html: "Modifica la tua password usando il <br/>codice numerico ricevuto tramite email"}}></Typography>
                </Box>
                <FormPassword
                    errore={altro.errore}
                    loading={altro.loading}
                    cambiaSuccess={setSuccess}
                    conferma={confermaCambioPassword}
                ></FormPassword>
            </Stack>

            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Codice inviato con successo!'}
            ></AppAlert>
        </Box>
    );
}

export default DimenticaPassword;