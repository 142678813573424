import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';

function CellaCorpo({
    value, width, align, isFoto, isStato, isPreferiti,
    isAction, borderLeft, borderRight, onClick
}) {

    const scriviCampo = (campo) => {
        if(campo?.length > 0) return campo
        return '-'
    }

    return (
        <TableCell
            sx={{
                width:width ? width : 'auto', backgroundColor:'white', padding:'8px', borderBottom:'1px solid #dcdcdc',
                borderLeft:borderLeft ? '1px solid #dcdcdc' : 'none', borderRight:borderRight ? '1px solid #dcdcdc' : 'none'
            }}
            align={align}
        >
            {!isFoto && !isStato && !isPreferiti && !isAction &&
                <Typography sx={{
                    fontSize:'11px', fontWeight:'500'
                }}>
                    {scriviCampo(value)}
                </Typography>
            }

            {isFoto && <Box sx={{
                width:'26px', height:'26px', borderRadius:'26px',
                display:'flex', alignItems:'center', justifyContent:'center',
                backgroundColor:value ? '#EFFAF3' : '#FFC2C2',
                color:value ? '#8CC5AB' : 'red', fontSize:'9px'
            }}>{value ? 'SI' : 'NO'}</Box>}

            {isStato && <Box sx={{
                padding:'4px 4px', backgroundColor:value ? '#EFFAF3' : '#FFC2C2',
                color:value ? '#8CC5AB' : 'red', fontSize:'9px', borderRadius:'10px'
            }}>{value ? 'Completa' : 'Incompleta'}</Box>}

            {isPreferiti && <IconButton onClick={onClick} sx={{
                width:'26px', height:'26px', borderRadius:'26px', marginLeft:'12px',
                display:'flex', alignItems:'center', justifyContent:'center'
            }}>
                {value ? <BookmarkRoundedIcon sx={{color:'primary.main'}} /> : <BookmarkBorderRoundedIcon />
            }</IconButton>}
            
            {isAction && <Button disableElevation disableFocusRipple variant='text' sx={{
                padding:0, textTransform:'none', color:'black', fontSize:'11px',
                textDecorationLine:'underline !important', backgroundColor:'white'
            }} onClick={onClick}>Modifica</Button>}
        </TableCell>
    );
}

export default CellaCorpo;