import React, { useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ModalDate from './ModalDate';
import ModalAgenti from './ModalAgenti';
import AppTextField from './AppTextField';
import BottoneFiltro from './BottoneFiltro';
import MenuFiltraPer from './MenuFiltraPer';

import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ordinaPer = [
    {id:0, text:'Più recenti'},
    {id:1, text:'Più datati'},
    {id:2, text:'Stato di lavorazione'},
    {id:3, text:'Preferiti'}
]

function Filtri({agenti, filtri, cambiaFiltri, date, cambiaDate, esportaSegnalazioni}) {

    const [open, setOpen] = useState(0);
    const [anchorVariabili, setAnchorVariabili] = useState(null);

    const scriviDate = () => {
        const startDate = moment(date[0].startDate);
        const endDate = moment(date[0].endDate);
        let result = '';
        result += startDate.format('DD/MM') + ' - ' + endDate.format('DD/MM');
        return result;
    }

    const cambiaOrdinaPer = (value) => {
        cambiaFiltri({...filtri, ordina:value})
        setTimeout(() => {
            setOpen(0)
            setAnchorVariabili(null)
        }, 200);
    }

    const scriviOrdinaPer = () => {
        if(!filtri?.ordina) return 'Ordina per';
        return 'Ordina per: ' + ordinaPer[filtri?.ordina].text;
    }

    const scriviAgente = () => {
        if(!filtri?.agente) return 'Agente';
        const agente = agenti.find((el) => el.id == filtri?.agente);
        return 'Agente: ' + agente?.fullName;
    }
    
    return (
        <Box sx={{width:'calc(100% - 32px)', display:'flex',
            flexDirection:'row', backgroundColor:'white', padding:'12px 16px', 
            justifyContent:'space-between', border:'1px solid #dcdcdc', borderRadius:'6px'
        }}>
            <Stack direction='row' spacing='16px'>
                <AppTextField
                    width={'450px'}
                    isBianco={true}
                    name={'Email'}
                    isLabel={false}
                    error={null}
                    placeholder={'Ricerca Segnalazione'}
                    Icon={<SearchIcon sx={{width:'20px', height:'auto', marginLeft:'6px'}} />}
                    register={{value:filtri.ricerca, onChange:(e) => cambiaFiltri({...filtri, ricerca:e.target.value})}}
                ></AppTextField>

                <BottoneFiltro
                    text={scriviOrdinaPer()}
                    onClick={(event) => {
                        setAnchorVariabili(event.currentTarget)
                        setOpen(1)
                    }}
                    Icon={<FilterListIcon sx={{width:'20px', height:'auto'}} />}
                ></BottoneFiltro>
                <BottoneFiltro
                    text={date ? scriviDate() : ''}
                    onClick={() => setOpen(2)}
                    Icon={<CalendarTodayIcon sx={{width:'20px', height:'auto'}} />}
                ></BottoneFiltro>
                <BottoneFiltro
                    text={scriviAgente()}
                    onClick={() => setOpen(3)}
                    Icon={<WorkOutlineIcon sx={{width:'20px', height:'auto'}} />}
                ></BottoneFiltro>
            </Stack>
            <BottoneFiltro
                text={'Esporta'}
                onClick={esportaSegnalazioni}
                Icon={<FileDownloadIcon sx={{width:'20px', height:'auto'}} />}
            ></BottoneFiltro>

            <MenuFiltraPer
                open={open === 1}
                elements={ordinaPer}
                value={filtri?.ordina}
                cambiaValue={cambiaOrdinaPer}
                anchorVariabili={anchorVariabili}
                onClose={() => {setOpen(0); setAnchorVariabili(null)}}
            ></MenuFiltraPer>

            <ModalAgenti
                open={open === 3}
                agenti={agenti}
                value={filtri?.agente}
                onClose={() => setOpen(0)}
                pulisciFiltro={() => cambiaFiltri({...filtri, agente:null})}
                cambiaValue={(value) => cambiaFiltri({...filtri, agente:value})}
            ></ModalAgenti>
            <ModalDate
                open={open === 2}
                date={date}
                onClose={() => setOpen(0)}
                pulisciFiltro={() => setOpen(0)}
                cambiaDate={(date) => cambiaDate(date)}
            ></ModalDate>
        </Box>
    );
}

export default Filtri;