import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function RigaCampo({Icon, text}) {

    return (
        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', paddingBottom:'4px'}}>
            <Typography sx={{fontSize:'14px', fontWeight:'500', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'left', color:'black'
            }}>{text}</Typography>
        </Box>
    );
}

export default RigaCampo;