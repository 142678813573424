import client from "./client";
import Storage from "../libs/storage";
import { API_URL, JWT_TOKEN } from "../config";

const endpoint1 = API_URL + '/auth/'
const endpoint2 = API_URL + '/users/'

const logout = async () => {
    Storage.deleteKey(JWT_TOKEN);
}
  
const login = async (data) => {
    try {
        const result = await client.post(endpoint1 + "login", data);
        Storage.setItem(JWT_TOKEN, result.data.token);
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const refreshToken = async() => {
    try {
        const result = await client.post(endpoint1 + "refresh-token", {});
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const getMe = async() => {
    try {
        const result = await client.get(endpoint2 + 'me');
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const modificaPassword = async(data) => {
    try {
        const result = await client.put(endpoint2 + 'me/change-password', data);
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const forgotPassword = async(data) => {
    try {
        const result = await client.post(endpoint1 + "send-forgot-password-code", data)
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const resetPassword = async(data) => {
    try {
        const result = await client.post(endpoint1 + "reset-password", data)
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

export default {
    //auth
    logout,
    login,
    refreshToken,
    resetPassword,
    forgotPassword,

    //user
    getMe,
    modificaPassword
}