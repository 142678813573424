import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import authApi from '../api/auth'
import AppTextField from '../components/AppTextField';

function ModificaPassword(props) {

    const navigate = useNavigate()
    const [altro, setAltro] = useState({errore:null, loading:false})

    const schema = yup.object().shape({
        vecchia: yup.string().required('La vecchia password è obbligatoria.').min(8, 'La password deve avere almeno 8 caratteri.'),
        nuova: yup.string().required('La nuova password è obbligatoria.').min(8, 'La password deve avere almeno 8 caratteri.'),
        ripeti: yup.string().required('Ripeti la nuova password.').min(8, 'La password deve avere almeno 8 caratteri.'),
    });
    const {register, handleSubmit, formState: { errors }} = useForm({resolver: yupResolver(schema)});

    const conferma = async(valori) => {
        if(altro.loading) return
        if(valori.nuova != valori.ripeti) return setAltro({...altro, errore:'La password ripetuta non è corretta!'})
        setAltro({...altro, loading:true, errore:null})
        //effettuo il login
        let result = await authApi.modificaPassword({
            vecchia:valori.vecchia, nuova:valori.nuova
        })
        if(result.statusText != 'OK') return setAltro({...altro, loading:false, errore:result.data.message})
        //correct
        setTimeout(() => {
            setAltro({...altro, errore:null, loading:false})
            navigate('/account', {state:{isPwd:true}})
        }, 300);
    }
    
    return (
        <Box sx={{width:'100%', padding:'80px 0', backgroundColor:'#f9f9f9', 
            overflow:'auto', display:'flex', justifyContent:'center', alignItems:'center'
        }}>
            <Stack direction='column' spacing='30px' sx={{paddingBottom:'80px', width:'400px'}}>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Typography sx={{fontSize:'36px', fontWeight:'500', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Modifica la Password</Typography>
                    <Typography sx={{paddingTop:'6px', paddingBottom:'4px', fontSize:'14px', fontWeight:'400', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#96A0AD'
                    }} dangerouslySetInnerHTML={{__html: "Modifica la password inserendo quella vecchia<br/> e confermando quella nuova"}}></Typography>
                </Box>

                <Box
                    component='form'
                    id="cambio-pwd-form"
                    name="cambio-pwd-form"
                    data-name="Cambio PWD Form"
                    onSubmit={handleSubmit(conferma)}
                >
                    <Stack direction='column' spacing='30px'>
                        <AppTextField
                            isBianco={true}
                            name={'Vecchia Password'}
                            isLabel={true}
                            isPassword={true}
                            width={'100%'}
                            error={errors.vecchia?.message}
                            placeholder={'Inserisci la vecchia password'}
                            register={register("vecchia", { required: true })}
                        ></AppTextField>

                        <AppTextField
                            isBianco={true}
                            name={'Nuova Password'}
                            isLabel={true}
                            isPassword={true}
                            width={'100%'}
                            error={errors.nuova?.message}
                            placeholder={'Inserisci la nuova password'}
                            register={register("nuova", { required: true })}
                        ></AppTextField>

                        <AppTextField
                            isBianco={true}
                            name={'Ripeti Password'}
                            isLabel={true}
                            isPassword={true}
                            width={'100%'}
                            error={errors.ripeti?.message}
                            placeholder={'Ripeti la nuova password'}
                            register={register("ripeti", { required: true })}
                        ></AppTextField>

                        {Boolean(altro.errore) &&
                            <Typography sx={{fontSize:'15px', color:'#FC2A1B', 
                                fontWeight:'500', lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center'
                            }}>{altro.errore}</Typography>
                        }

                        <Stack direction='row' spacing='16px' sx={{width:'400px'}}>
                            <LoadingButton
                                type="submit"
                                color={"primary"}
                                variant="contained"
                                sx={{flex:1, border:'none', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                                disableElevation={true}
                                onClick={() => null}
                                loading={altro.loading}
                                loadingPosition={'center'}
                            >
                                <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                                    letterSpacing:'-0.2', textAlign:'left', color:'white'
                                }}>Conferma</Typography>
                            </LoadingButton>
                            <Button
                                color={"nero"}
                                variant="contained"
                                sx={{flex:1, border:'none', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                                disableElevation={true}
                                onClick={() => navigate('/account')}
                            >
                                <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                                    letterSpacing:'-0.2', textAlign:'left', color:'white'
                                }}>Annulla</Typography>
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
}

export default ModificaPassword;