import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import utils from '../libs/utils';

function ZonaStatistiche({
    visible, date, agente, listaAgenti, nYear, nMonth, nPeriod, bestAgenti, bestProdotti, actualYear, actualMonth
}) {

    const isDate = Boolean(date[0] && !date[0]?.isFirst)

    const scriviAgente = () => {
        if(!agente) return 'Agente';
        const appo = listaAgenti.find((el) => el.id == agente);
        return appo?.fullName;
    }
    
    return (
        <Stack direction='row' spacing='24px' sx={{
            width:'100%', alignItems:'center', marginTop:'24px', display:visible ? 'flex' : 'none'
        }}>
            {Boolean(agente) && <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', height:'300px',
                backgroundColor:'white', borderRadius:'6px', border:'1px solid #dcdcdc', flex:1
            }}>
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'36px'
                }}>Agente Selezionato</Typography>
                <Typography sx={{fontSize:'34px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'80px'
                }}>{scriviAgente()}</Typography>
            </Box>}

            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', height:'300px',
                backgroundColor:'white', borderRadius:'6px', border:'1px solid #dcdcdc', flex:1
            }}>
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'36px'
                }}>Segnalazioni {actualYear}</Typography>
                <Typography sx={{fontSize:'38px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'80px'
                }}>{nYear}</Typography>
            </Box>

            {!isDate && <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', height:'300px',
                backgroundColor:'white', borderRadius:'6px', border:'1px solid #dcdcdc', flex:1
            }}>
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'36px'
                }}>Segnalazioni {utils.scriviMese(actualMonth)}</Typography>
                <Typography sx={{fontSize:'38px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'80px'
                }}>{nMonth}</Typography>
            </Box>}

            {isDate && <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', height:'300px',
                backgroundColor:'white', borderRadius:'6px', border:'1px solid #dcdcdc', flex:1
            }}>
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'center', color:'black', paddingTop:'36px'
                }}>Segnalazioni Date Selezionate</Typography>
                <Typography sx={{fontSize:'38px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'80px'
                }}>{nPeriod}</Typography>
            </Box>}

            {!Boolean(agente) && <Box sx={{display:'flex', flexDirection:'column', alignItems:'flex-start', height:'300px',
                backgroundColor:'white', borderRadius:'6px', border:'1px solid #dcdcdc', flex:1
            }}>
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'36px', paddingLeft:'45px'
                }}>Migliori Agenti</Typography>
                {bestAgenti?.length === 0 && 
                    <Box component='span' sx={{padding:'30px 45px'}}>Nessun agente trovato.</Box>
                }
                <Stack direction='column' spacing='14px' sx={{padding:'30px 45px'}}>
                    {bestAgenti?.slice(0, 5).map((agente, index) => (
                        <Typography key={index} sx={{
                            fontSize:'15px', fontWeight:'400', textAlign:'left', color:'black'
                        }}>{index + 1}: {agente.fullName} ({agente.cont})</Typography>
                    ))}
                </Stack>
            </Box>}

            <Box sx={{display:'flex', flexDirection:'column', alignItems:'flex-start', height:'300px',
                backgroundColor:'white', borderRadius:'6px', border:'1px solid #dcdcdc', flex:1
            }}>
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black', paddingTop:'36px', paddingLeft:'45px'
                }}>Migliori Prodotti</Typography>
                {bestProdotti?.length === 0 && 
                    <Box component='span' sx={{padding:'30px 45px'}}>Nessun prodotto trovato.</Box>
                }
                <Stack direction='column' spacing='14px' sx={{padding:'30px 45px'}}>
                    {bestProdotti?.slice(0, 5).map((prod, index) => (
                        <Typography noWrap key={index} sx={{
                            fontSize:'15px', fontWeight:'400', textAlign:'left', color:'black'
                        }}>{index + 1}: {prod.code} ({prod.cont})</Typography>
                    ))}
                </Stack>
            </Box>
        </Stack>
    );
}

export default ZonaStatistiche;