import { useContext } from "react";

import DateContext from "./context";

const useDate = () => {

    const { date, setDate } = useContext(DateContext);

    const aggDate = (v) => {
        setDate(v)
    }

    return { 
        date: date,
        aggDate
    }
}

export default useDate;