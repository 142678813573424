import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function CampoAccount({name, value}) {
    
    return (
        <Box sx={{display:'flex', flexDirection:'column', width:'100%', cursor:'not-allowed'}}>
            <Typography sx={{fontSize:'14px', fontWeight:'600', marginBottom:{xs:'4px', sm:'4px'},
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'black'
            }}>{name}</Typography>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', height:'40px', 
                justifyContent:'space-between', width:'100%', maxWidth:'100%', padding:'0 12px',
                borderRadius:'5px', border:'1px solid #DCDCDC', backgroundColor: 'white', fontSize:'15px'
            }}>
                {value}
            </Box>
        </Box>
    );
}

export default CampoAccount;