import React, { useState } from 'react';
import moment from 'moment/moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import utils from '../libs/utils';
import ModalDate from './ModalDate';
import ModalAgenti from './ModalAgenti';
import BottoneFiltro from './BottoneFiltro';

import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

function FiltriStatistiche({date, agente, listaAgenti, cambiaFiltri}) {

    const [open, setOpen] = useState(0);
    const isDate = Boolean(date[0] && !date[0]?.isFirst);

    const scriviDate = () => {
        const startDate = moment(date[0].startDate);
        const endDate = moment(date[0].endDate);
        const year1 = startDate.get('year')
        const year2 = endDate.get('year');
        let diverso = year1 != year2;
        let result = '';
        result += startDate.format('DD') + ' ' + utils.scriviMese(parseInt(startDate.get('month'))) 
            + (diverso ? ' ' + startDate.format('YYYY') : '');
        result += ' - '
        result += endDate.format('DD')+ ' ' + utils.scriviMese(parseInt(endDate.get('month')))
            + ' ' + endDate.format('YYYY');
        return result;
    }

    const pulisciDate = () => {
        cambiaFiltri({agente, date:[{
            startDate: new Date(),
            endDate: new Date(),
            key:'selection', isFirst:true
        }]})
    }
    
    return (
        <Box sx={{width:'calc(100% - 48px)', display:'flex', alignItems:'center',
            flexDirection:'row', backgroundColor:'white', padding:'0 24px', height:'75px', 
            justifyContent:'space-between', border:'1px solid #dcdcdc', borderRadius:'6px'
        }}>
            <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                letterSpacing:'1.2', textAlign:'left', color:'black'
            }}>Panoramica</Typography>

            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                {isDate && 
                    <IconButton sx={{width:'36px', height:'36px', borderRadius:'25px',
                        backgroundColor:'transparent', border:'none', marginRight:'12px'
                    }} onClick={() => pulisciDate()}>
                        <CancelPresentationIcon sx={{width:'28px', height:'auto', color:'black'}} />
                    </IconButton>
                }
                <Typography sx={{fontSize:'18px', fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'1.2', textAlign:'left', color:'black'
                }}>{isDate ? scriviDate() : "Anno {anno} - ".replace('{anno}', moment().format('YYYY')) + moment().format('DD MMMM YYYY')}</Typography>
            </Box>

            <Stack direction='row' spacing='16px' sx={{height:'45px'}}>
                <BottoneFiltro
                    text={'Date'}
                    selected={date[0] && !date[0]?.isFirst}
                    onClick={() => setOpen(1)}
                    Icon={<CalendarTodayIcon sx={{width:'20px', height:'auto'}} />}
                ></BottoneFiltro>
                <BottoneFiltro
                    text={'Agente'}
                    selected={Boolean(agente)}
                    onClick={() => setOpen(2)}
                    Icon={<WorkOutlineIcon sx={{width:'20px', height:'auto'}} />}
                ></BottoneFiltro>
            </Stack>

            <ModalAgenti
                open={open === 2}
                agenti={listaAgenti}
                value={agente}
                onClose={() => setOpen(0)}
                pulisciFiltro={() => cambiaFiltri({date, agente:null})}
                cambiaValue={(value) => cambiaFiltri({date, agente:value})}
            ></ModalAgenti>
            <ModalDate
                open={open === 1}
                date={date}
                onClose={() => setOpen(0)}
                pulisciFiltro={() => {setOpen(0); cambiaFiltri({agente, date:[{
                    startDate: null,
                    endDate: new Date(),
                    key:'selection', isFirst:true
                }]})}}
                cambiaDate={(date) => cambiaFiltri({agente, date:date})}
            ></ModalDate>
        </Box>
    );
}

export default FiltriStatistiche;