import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppTextField from './AppTextField';

function FormPassword({conferma, errore, loading, cambiaSuccess}) {

    const isErrore = Boolean(errore)

    const schema = yup.object().shape({
        email: yup.string().lowercase().required('La mail è obbligatoria.').email('La mail deve essere una email valida.'),
        token: yup.string().required('Il codice è obbligatorio.').min(6, 'Il codice è a 6 cifre.').max(6, 'Il codice è a 6 cifre.'),
        password: yup.string().required('La password è obbligatoria').min(8, 'La password deve avere almeno 8 caratteri.')
    });
    const {register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <Box
            component='form'
            id="password-form"
            name="password-form"
            data-name="Password Form"
            onSubmit={handleSubmit(conferma)}
            sx={{width:{xs:'100%', sm:'fit-content'}}}
        >
            <Stack direction='column' spacing='24px' sx={{width:{xs:'100%', sm:'fit-content'}}}>
                <AppTextField
                    isBianco={true}
                    name={'Email'}
                    isLabel={true}
                    error={errors.email?.message}
                    placeholder={'Inserisci la tua email'}
                    register={register("email", { required: false })}
                ></AppTextField>
                <AppTextField
                    isBianco={true}
                    name={'Token'}
                    isLabel={true}
                    error={errors.token?.message}
                    placeholder={'Inserisci il codice numerico'}
                    register={register("token", { required: false })}
                ></AppTextField>
                <AppTextField
                    isBianco={true}
                    name={'Password'}
                    isLabel={true}
                    isPassword={true}
                    error={errors.password?.message}
                    placeholder={'Inserisci la nuova password'}
                    register={register("password", { required: false })}
                ></AppTextField>
                <Box sx={{display:'flex', flexDirection:'column', paddingTop:'12px'}}>
                    {isErrore &&
                        <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, marginBottom:{xs:'4px', sm:'8px'}, 
                            fontWeight:'600', lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#FC2A1B'
                        }}>{errore}</Typography>
                    }
                    <LoadingButton
                        type="submit"
                        color={"primary"}
                        variant="contained"
                        sx={{border:'none', width:'100%', padding:'12px 40px', borderRadius:'5px', textTransform: 'none'}}
                        disableElevation={true}
                        onClick={() => null}
                        loading={loading}
                        loadingPosition={'center'}
                    >
                        <Typography component='span' sx={{fontSize:'16px', fontWeight:'500', lineHeight:'normal', 
                            letterSpacing:'-0.2', textAlign:'left', color:'white'
                        }}>Modifica password</Typography>
                    </LoadingButton>
                </Box>
                <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', paddingTop:'12px'}}>
                    <Typography sx={{fontSize:'14px', fontWeight:'400', cursor:'pointer',
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'primary.main'
                    }} component='a' href={'/login'}>Torna al login</Typography>
                    <Typography sx={{fontSize:'14px', fontWeight:'400', cursor:'pointer', marginTop:'12px',
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'primary.main'
                    }} component='a' href={'#'} onClick={() => cambiaSuccess(false)}>Non hai ricevuto il codice?</Typography>
                </Box>
            </Stack>
        </Box>
    );
}

export default FormPassword;