import { useContext } from "react";

import FiltriStatisticheContext from "./context";

const useFiltriStatistiche = () => {

    const { filtriStatistiche, setFiltriStatistiche } = useContext(FiltriStatisticheContext);

    const aggFiltriStatistiche = (values) => setFiltriStatistiche({...values})

    return { 
        filtriStatistiche: filtriStatistiche,
        aggFiltriStatistiche
    }
}

export default useFiltriStatistiche;