import './styles/daterange.css'
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment';

import authApi from './api/auth';
import {JWT_TOKEN} from "./config";
import storage from './libs/storage';
import agentiApi from './api/agenti';
import Navigator from './pages/Navigator';
import segnalazioniApi from './api/segnalazioni';
import AuthContext from './controllers/auth/context';
import DateContext from './controllers/date/context';
import AgentiContext from './controllers/agenti/context';
import FiltriContext from './controllers/filtri/context';
import SegnalazioniContext from './controllers/segnalazioni/context';
import FiltriStatisticheContext from './controllers/filtriStatistiche/context';

const theme = createTheme({
    palette: {
        primary: {
            main: '#130189',
            contrastText: "#FFF",
            dark: "#130189"
        },
        secondary: {
            main: '#F9F5FF',
            contrastText: "#000",
            dark: "#F9F5FF"
        },
        bianco: {
            main: '#FFF',
            contrastText: "#000",
            dark: "#FFF"
        },
        nero: {
            main: '#000',
            contrastText: "#FFF",
            dark: "#000"
        },
        rosso: {
            main: '#FFC2C2',
            contrastText: "#7A3C02",
            dark: "#FFC2C2"
        },
        verde: {
            main: '#EFFAF3',
            contrastText: "#027A48",
            dark: "#EFFAF3"
        },
    },
    typography: {
        fontFamily: `"Inter", sans-serif;`
    }
})

function App() {

    const [date, setDate] = useState(null)
	const [user, setUser] = useState({user:null, loading:true})
    const [agenti, setAgenti] = useState({lista:[], loading:true})
    const [segnalazioni, setSegnalazioni] = useState({lista:[], loading:true})
    const [filtri, setFiltri] = useState({ricerca:'', agente:null, ordina:0, page:1})
    const [filtriStatistiche, setFiltriStatistiche] = useState({date:[], agente:null})

    useEffect(() => {
		const reloadUser = async () => {
            //controllo pagina
            if(window.location.pathname == '/login') return
            //scarico info utente
			let result = await authApi.getMe()
            if(result.statusText != 'OK') {
                //mando a login
                storage.setItem(JWT_TOKEN, null)
                return window.location.replace('/login')
            }
            setUser({user:result.data, loading:false})
            //aggiorno il token
            result = await authApi.refreshToken()
            if(result.statusText != 'OK') return
            storage.setItem(JWT_TOKEN, result.data.token)
		}
        reloadUser()
    }, [])

    useEffect(() => {
        const aggDate = () => {
            const dataI = new Date();
            dataI.setMonth(dataI.getMonth() - 3);
            const dataF = new Date();
            setDate([{
                startDate: dataI,
                endDate: dataF,
                key:'selection'
            }])
        }
        const aggDateStatistiche = () => {
            setFiltriStatistiche({...filtriStatistiche, date:[{
                startDate: new Date(),
                endDate: new Date(),
                key:'selection', isFirst:true
            }]})
        }
        aggDate()
        aggDateStatistiche()
    }, [])

	useEffect(() => {
        const aggSegnalazioni = async() => {
            if(!segnalazioni.loading) setSegnalazioni({...segnalazioni, loading:true})
            const end = moment(date[0].endDate);
			const start = moment(date[0].startDate);
            const result = await segnalazioniApi.getSegnalazioni(start.toISOString(), end.toISOString());
            if(result.statusText != 'OK') return;
            setTimeout(() => {
                setSegnalazioni({lista:result.data, loading:false})
            }, 600);
        }
        if(user?.user && date) aggSegnalazioni()
    }, [user?.user, date])

    useEffect(() => {
        const aggAgenti = async() => {
            const result = await agentiApi.getAgenti();
            if(result.statusText != 'OK') return;
            setAgenti({lista:result.data, loading:false})
        }
        if(user?.user) aggAgenti()
    }, [user?.user])

	return (
		<ThemeProvider theme={theme}>
			<AuthContext.Provider value={{user, setUser}}>
                <SegnalazioniContext.Provider value={{segnalazioni, setSegnalazioni}}>
                   <DateContext.Provider value={{date, setDate}}>
                        <AgentiContext.Provider value={{agenti, setAgenti}}>
                            <FiltriContext.Provider value={{filtri, setFiltri}}>
                                <FiltriStatisticheContext.Provider value={{filtriStatistiche, setFiltriStatistiche}}>
                                    <Navigator></Navigator>
                                </FiltriStatisticheContext.Provider>
                            </FiltriContext.Provider>
                        </AgentiContext.Provider>
                    </DateContext.Provider> 
                </SegnalazioniContext.Provider>
			</AuthContext.Provider>
		</ThemeProvider>
	);
}

export default App;
