import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

const AppMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor:'white',
        borderRadius:'6px',
        border:'1px solid white'
    }
});

const Backdrop = styled('div')({
    position: 'fixed', top: 0,
    right: 0, bottom: 0, left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

function MenuFiltraPer({anchorVariabili, open, onClose, elements, value, cambiaValue}) {

    return (<>
        <Backdrop style={{display:open ? 'block' : 'none', zIndex:3000}} onClick={onClose} />
        <AppMenu
            anchorEl={anchorVariabili}
            open={open}
            onClose={onClose}
            TransitionComponent={Fade}
            hideBackdrop={false}
            sx={{marginTop:'10px', zIndex:4000}}
            anchorOrigin={{vertical:"bottom", horizontal:"left"}}
            disableScrollLock={true}
        >
            <Box sx={{padding:'0', margin:'-8px 0'}}>
                <List component="nav" aria-label="main filters order">
                    {elements.map((el, index) => (
                        <ListItemButton
                            key={index}
                            selected={value === el.id}
                            onClick={() => cambiaValue(el.id)}
                        >
                            <Typography component='span' sx={{fontSize:'14px', fontWeight:'500',
                                lineHeight:'normal', letterSpacing:'normal', color:'black'
                            }}>{el.text}</Typography>
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        </AppMenu>
    </>);
}

export default MenuFiltraPer;