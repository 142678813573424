import client from "./client";
import { API_URL } from "../config";

const endpoint = API_URL + '/reportings/'

const getSegnalazioni = async (dataInizio, dataFine) => {
    try {
        const result = await client.get(endpoint + 'admin/list/' + dataInizio + '/' + dataFine);
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const getStatistiche = async (dataInizio, dataFine) => {
    try {
        const result = await client.get(endpoint + 'admin/statistics/' + dataInizio + '/' + dataFine);
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const modificaSegnalazione = async (id, data) => {
    try {
        const result = await client.put(endpoint + 'admin/' + id, data);
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

const segnalazionePreferita = async(id, preferred) => {
    try {
        const result = await client.put(endpoint + 'admin/preferred/' + id, {preferred});
        return result;
    } catch(error) {
        if (error.response) {
            return {ok:false, data:error.response.data}
        } else return {ok:false, data:'Errore di comunicazione.'}
    }
}

export default {
    getSegnalazioni,
    getStatistiche,
    modificaSegnalazione,
    segnalazionePreferita
}